<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">구매 요청 정보</strong>
      </div>
    </div>
    <div class="tbl_comm tbl_view">
      <table>
        <colgroup>
          <col style="width: 192px" />
          <col />
          <col style="width: 192px" />
          <col />
        </colgroup>
        <tbody>
          <tr>
            <th>정산서명<sup>*</sup></th>
            <td colspan="3">
              <Input
                placeholder="정산서명을 입력하세요"
                :value.sync="model.settlementName"
                :is-error="isError.settlementName"
                :maxLength="100"
                @focus="isError.settlementName = false"
                @focusout="onFocusout"
              />
            </td>
          </tr>
          <tr>
            <th>정산서 번호</th>
            <td>{{ model.isModifyMode ? model.settlementCid : "정산서 등록 후 생성 됩니다" }}</td>
            <th>정산서 등록일</th>
            <td>
              {{ model.isModifyMode ? "서버에서최신날짜내려줘야됨" : "-" | dateTimeStringFormat }}
            </td>
          </tr>
          <tr>
            <th>구매 요청 정보<sup>*</sup></th>
            <td v-if="docflag == ''" colspan="3">
              <ConnectViewOnlyOne
                :dataList="model.purchaseRequestWf"
                :isDisabled="model.purchaseRequestWf.aprvlStatus == '-'"
                @onClickConnect="onClickConnectPurchaseRequestWf"
              />
            </td>
            <td v-else colspan="3">
              <div class="row_pack">
                <button
                  type="button"
                  class="btn_small btn_fourthly"
                  @click="$emit('onClickPurchaseRequest')"
                >
                  구매 요청 정보 불러오기
                </button>
                <p class="desc_tip">
                  <span class="ico_account ico_exclam" />구매 요청 정보를 선택하여 계약정보를 불러올
                  수 있습니다.
                </p>
              </div>
              <ConnectEditOnlyOne
                :dataList="model.purchaseRequestWf"
                :isDisabled="model.purchaseRequestWf.aprvlStatus == '-'"
                @onClickConnect="onClickConnectPurchaseRequestWf"
                @onClickRemove="onClickDeletePurchaseRequestWf"
              />
            </td>
          </tr>
          <tr>
            <th>등록 정산서</th>
            <td colspan="3">
              <ConnectView
                :dataList="connectDataList"
                titleKeyName="settlementName"
                :disabledData="{ aprvlStatus: '-' }"
                @onClickConnect="onClickConnectStaementInfo"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ConnectEdit from "@/components/common/connect/ConnectEdit";
import ConnectEditOnlyOne from "@/components/common/connect/ConnectEditOnlyOne";
import ConnectView from "@/components/common/connect/ConnectView";
import ConnectViewOnlyOne from "@/components/common/connect/ConnectViewOnlyOne";
import Input from "@/_approval/components/common/input/Input";
import { getPurchaseDraftDetailPath, getStatementDetailPath } from "@/utils/DetailPathUtils";

export default {
  name: "PurchaserequestInfo",
  components: {
    ConnectEdit,
    ConnectEditOnlyOne,
    ConnectView,
    ConnectViewOnlyOne,
    Input,
  },
  props: {
    model: Object,
    connectDataList: Array,
    docflag: String,
    isError: Object,
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
    onClickConnectPurchaseRequestWf(item) {
      console.log(item);
      const { requestCid } = item;
      const path = `${this.$routerPath.PURCHASE_VIEW}/${requestCid}`;

      this.$windowOpen(path);
    },
    onClickDeletePurchaseRequestWf(item) {
      // deleteData => model.purchaseRequestWf = {}
      this.model.purchaseRequestWf = {};
      this.model.certificateList.forEach((item) => {
        item.contract = {};
        item.contractInspect = [];
      });
      this.$emit("clearConnectDataList");
    },
    onClickConnectStaementInfo(item) {
      const path =
        getStatementDetailPath(item.docStatus, item.aprvlStatus) + "?docNo=" + item.settlementCid;
      this.$windowOpen(path);
    },
  },
};
</script>
